import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_USER_ACCESS_API_URL,
});


instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        if (token) {
            // Set the token in the request headers
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// get Single User Data Access List  
export const getUserWiseDataAccess = async (orgId, userId) => {
    try {
        const { data } = await instance.get(`user-data-role-summary/${orgId}/${userId}`);
        return data;
    } catch (err) {
        return err;
    }
};

// get All User Data Access List  
export const getAllUserWiseDataAccess = async (orgId) => {
    try {
        const { data } = await instance.get(`user-data-role-summary/${orgId}`);
        return data;
    } catch (err) {
        return err;
    }
};



//get data item
export const getDataList = async () => {
    try {
        const { data } = await instance.get(`data-access-control-field`,);
        return data;
    } catch (err) {
        return err;
    }
}


//create data item
export const createDataItem = async (payload) => {
    try {
        const { data } = await instance.post(`data-access-control-field`, payload);
        return data;
    } catch (err) {
        return err;
    }
}



// data item update
export const updateDataItem = async (dataID, payload) => {
    try {
        const { data } = await instance.put(`data-access-control-field/${dataID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


// data item delete
export const deleteDataItem = async (dataID) => {
    try {
        const { data } = await instance.delete(`data-access-control-field/${dataID}`);
        return data;
    } catch (err) {
        return err;
    }
};


// =======================================


//get data list for orgnsaition 
export const getOrgDataItemList = async () => {
    try {
        const { data } = await instance.get(`orgaization-data-access-control-field`,);
        return data;
    } catch (err) {
        return err;
    }
}


//select data item 
export const selectDataItem = async (organization_id, id) => {
    try {
        const { data } = await instance.post(`orgaization-data-access-control-field/${organization_id}/${id}/create`,);
        return data;
    } catch (err) {
        return err;
    }
}

//unselect data item
export const unSelectDataItem = async (id) => {
    try {
        const { data } = await instance.delete(`orgaization-data-access-control-field/${id}`,);
        return data;
    } catch (err) {
        return err;
    }
}


// ===================================





//Get DataRoe List

export const getDataRoleList = async () => {
    try {
        const { data } = await instance.get(`organization-data-role`,);
        return data;
    } catch (err) {
        return err;
    }
}




//create Data Role
export const createDataRole = async (payload) => {
    try {
        const { data } = await instance.post(`organization-data-role`, payload);
        return data;
    } catch (err) {
        return err;
    }
}


//Update Data Role
export const updateDataRole = async (id, payload) => {
    try {
        const { data } = await instance.put(`organization-data-role/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}


//Delete Data Role
export const deleteDataRole = async (id) => {
    try {
        const { data } = await instance.delete(`organization-data-role/${id}`);
        return data;
    } catch (err) {
        return err;
    }
}


//********************************************* Mapping API's *********************************************

//create Mapping to Data-Role
export const assignValueToData = async (dataId, payload) => {
    try {
        const { data } = await instance.post(`organization-data-role-mapping/${dataId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}

//create Mapping to Data-Role
export const updateValueToData = async (orgID, roleID, payload) => {
    try {
        const { data } = await instance.put(`organization-data-role-mapping/${orgID}/${roleID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}

// get mapping data of data-role 
export const getDataRoleValue = async (orgID, dataRoleId) => {
    try {
        const { data } = await instance.get(`organization-data-role-mapping/${orgID}/${dataRoleId}`);
        return data;
    } catch (err) {
        return err;
    }
}

// delete mapping from data-role 
export const DeleteAssignDataRole = async (orgId, dataRoleId) => {
    try {
        const { data } = await instance.delete(`organization-data-role-mapping/${orgId}/${dataRoleId}`);
        return data;
    } catch (err) {
        return err;
    }
}


//**************** Deesignation to Role Mapping API's ***************

//create Mapping to Data-Role
export const assignDesignationToRole = async (orgId, payload) => {
    try {
        const { data } = await instance.post(`organization-data-role-designation-mapping/${orgId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}
export const updateDesignationToData = async (orgID, roleID, payload) => {
    try {
        const { data } = await instance.put(`organization-data-role-designation-mapping/${orgID}/${roleID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}


//**************** Department to Role Mapping API's ***************

//create Mapping to Data-Role
export const assignDepartmentToRole = async (orgId, payload) => {
    try {
        const { data } = await instance.post(`organization-data-role-deparment-mapping/${orgId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}
export const updateDepartmentToData = async (orgID, roleID, payload) => {
    try {
        const { data } = await instance.put(`organization-data-role-deparment-mapping/${orgID}/${roleID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}


//**************** Practice Area to Role Mapping API's ***************

//create Mapping to Data-Role
export const assignPracticeAreaToRole = async (orgId, payload) => {
    try {
        const { data } = await instance.post(`organization-data-role-practice-area-mapping/${orgId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}
export const updatePracticeAreaToData = async (orgID, roleID, payload) => {
    try {
        const { data } = await instance.put(`organization-data-role-practice-area-mapping/${orgID}/${roleID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}




//**************** Module assign to Role Mapping API's ***************

//create Mapping to Data-Role
export const getAssignModuleToDataRole = async (orgId, roleID) => {
    try {
        const { data } = await instance.get(`organization-data-role-module-mapping/${orgId}/${roleID}`);
        return data;
    } catch (err) {
        return err;
    }
}


//assign module to Data-Role
export const assignModuleToDataRole = async (orgId, payload) => {
    try {
        const { data } = await instance.post(`organization-data-role-module-mapping/${orgId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}

//unassign module to Data-Role
export const unAssignModuleToDataRole = async (orgId, moduleId) => {
    try {
        const { data } = await instance.delete(`organization-data-role-module-mapping/${orgId}/${moduleId}`);
        return data;
    } catch (err) {
        return err;
    }
}




//**************** Module assign to Role Mapping API's ***************

//create Mapping to Data-Role
export const getAssignUserToDataRole = async (orgId, roleID) => {
    try {
        const { data } = await instance.get(`user-data-role-mapping-role/${orgId}/${roleID}`);
        return data;
    } catch (err) {
        return err;
    }
}


//assign module to Data-Role
export const assignUserToDataRole = async (orgId, payload) => {
    try {
        const { data } = await instance.post(`user-data-role-mapping/${orgId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}

//unassign module to Data-Role
export const unAssigUserToDataRole = async (orgId, moduleId) => {
    try {
        const { data } = await instance.delete(`user-data-role-mapping/${orgId}/${moduleId}`);
        return data;
    } catch (err) {
        return err;
    }
}