//remove empty aaray and value from Object
// export default function removeEmptyValuesAndArrays(obj) {
//     const filteredEntries = Object.entries(obj)?.filter(([key, value]) => {
//         if (Array.isArray(value)) {
//             return value.length > 0;
//         } else if (typeof value === 'object') {
//             // Check if the object has any non-empty keys recursively
//             const nonEmptyKeys = Object.keys(value).filter(subKey => {
//                 const subValue = value[subKey];
//                 return subValue !== "" && subValue !== null && subValue !== undefined;
//             });
//             return nonEmptyKeys.length > 0;
//         } else {
//             return value !== "" && value !== null && value !== undefined;
//         }
//     });

//     return Object.fromEntries(filteredEntries);
// }




export default function removeEmptyValuesAndArrays(obj) {
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      // Recursively call the function for nested objects
      obj[key] = removeEmptyValuesAndArrays(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        // If the nested object is empty, remove it
        delete obj[key];
      }
    } else if (obj[key] === '' || obj[key] === null) {
      // Remove empty strings and null values
      delete obj[key];
    } else if (Array.isArray(obj[key]) && obj[key].length === 0) {
      // Remove empty arrays
      delete obj[key];
    }
  }
  return obj;
}



//convert file to base64
export const convertToBase64 = (file, callback) => {
  if (!file) {
    console.error('No file provided');
    return;
  }

  const reader = new FileReader();

  reader.onload = function (e) {
    const base64String = e.target.result;
    callback(base64String);
  };

  reader.readAsDataURL(file);
};



function capitalizeFirstLetter(str) {
  if (typeof str !== 'string') {
    return '';
  }

  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}


var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

export function numberToword(num) {
  if ((num = num.toString()).length > 9) return 'overflow';
  var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
  return capitalizeFirstLetter(str);
}



export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  // var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? 'h ' : 'h ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? 'm ' : 'm ') : '';
  // var sDisplay = s > 0 ? s + (s == 1 ? ' s' : ' s') : '';
  return hDisplay + mDisplay;
}

export const formatNumberIndian = (number, decimalPlaces = 2) => new Intl.NumberFormat('en-IN', {
  maximumFractionDigits: decimalPlaces,
  minimumFractionDigits: decimalPlaces
}).format(number)




export const getTimeDifferenceInSeconds = (start, end) => {
  // Parse start and end times to Date objects
  const startTime = new Date(start);
  const endTime = new Date(end);

  // Calculate the time difference in milliseconds
  const timeDiff = endTime.getTime() - startTime.getTime();

  // Convert milliseconds to seconds
  const seconds = Math.floor(timeDiff / 1000);

  return seconds;
}