import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Loader from 'ui-component/Loader';
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, TableContainer, TablePagination, TextField, CardContent, Paper, FormControlLabel, Switch } from '@mui/material';

import Scrollbar from 'components/Scrollbar';
import { UserListHead, UserListToolbar } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { styled } from '@mui/system';
import Toast from 'components/Toast';
import Iconify from 'components/Iconify';
import { createModule, createScreen, deleteModule, deleteScreen, getAllModule, getAllScreen, updateModule } from 'services/UserAccessMasterService';
import ModuleMoreMenu from './ModuleMoreMenu';
import AssignScreenModal from './AssignScreenModal';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '', label: '', alignRight: true },
    { id: 'action', label: 'Action', alignRight: true },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}


function ModuleMaster() {

    const [moduleData, SetModuleData] = useState([])
    const [IsLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterName, setFilterName] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [createModal, setCreateModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [assignModal, setAssignModal] = useState(false)
    const [moduleId, setModuleId] = useState('')


    const [moduleDataValue, setModuleDataValue] = useState({
        name: '',
        status: 'INACTIVE',
    });
    const [prevmoduleData, setprevModuleData] = useState([])
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    useEffect(() => {
        fetchModuleData()
    }, [])

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - moduleData?.length) : 0;

    const filterCoupon = applySortFilter(moduleData, getComparator(order, orderBy), filterName);

    const isDataNotFound = filterCoupon?.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = moduleData?.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setModuleDataValue((prev) => ({ ...prev, [name]: value }));
    };

    const handleSwitch = (name, value) => {
        let status
        if (value) {
            status = 'ACTIVE';
        } else {
            status = 'INACTIVE';
        }
        setModuleDataValue((prev) => ({ ...prev, [name]: status }));
    }



    //fetch all module data
    const fetchModuleData = async () => {
        const res = await getAllModule();
        if (res?.data) {
            SetModuleData(res?.data)
        }
        else {
            SetModuleData([])
        }
    }



    const EditModuleStatus = (id, data) => {
        setIsEdit(true)
        setModuleDataValue(data)
        setprevModuleData(data)
        setCreateModal(true)
    }

    const handelCloseModal = () => {
        setModuleDataValue({
            name: '',
            status: 'INACTIVE',
        })
        setCreateModal(false)
        setIsEdit(false)
        setModuleId('')
        setAssignModal(false)
    }


    const findChangedData = (previousObj, currentObj) => {
        const changedData = {};
        for (const key in previousObj) {
            if (previousObj[key] !== currentObj[key]) {
                changedData[key] = currentObj[key];
            }
        }
        return changedData;
    };


    // handle create update module
    const handleCreateUpdateModule = async (event) => {
        event.preventDefault()
        let payload = {
            ...moduleDataValue,
            status: moduleDataValue?.status
        }

        if (isEdit) {
            const newChangedKeys = findChangedData(prevmoduleData, moduleDataValue);

            const res = await updateModule(moduleDataValue?.id, newChangedKeys);
            if (res?.message === "Module data updated successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchModuleData();
                setIsEdit(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || 'Something went wrong'
                });
            }
        }
        else {
            const res = await createModule(payload);
            if (res.message === "Module data created successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchModuleData();
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res?.message || 'Something went wrong'
                });
            }
        }
    }


    //handle table toggle 
    const toggleActive = async (e, id) => {
        const tempScreenData = [...moduleData];
        tempScreenData.forEach(async (el) => {
            console.log("ël", el);
            if (el.id === id) {
                if (e.target.checked) {
                    el.status = 'ACTIVE';
                } else {
                    el.status = 'INACTIVE';
                }
                const payload = {
                    status: el.status,
                };
                const res = await updateModule(id, payload);
                console.log(res, 'resData');
                if (res.message === "Module data updated successfully.") {
                    setToastData({
                        open: true,
                        status: 'success',
                        message: res?.message || 'Something went wrong'
                    });
                    fetchModuleData();
                }
                else {
                    fetchModuleData();
                    setToastData({
                        open: true,
                        status: 'error',
                        message: res?.response?.data?.message || 'Something went wrong'
                    });
                }
            }
        });

    };

    const removeModuleData = async (id) => {
        const res = await deleteModule(id);
        if (res.message === "Module deleted successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchModuleData();
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || res?.message || 'Something went wrong'
            });
        }
    }


    const AssignScreen = (id) => {
        setAssignModal(true)
        setModuleId(id)
    }




    return (
        <>
            {IsLoading && <Loader />}
            <div className='country'>
                <Stack direction="row" justifyContent="flex-start" mb={2}>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="#"
                        onClick={() => setCreateModal(!createModal)}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        sx={{ color: "#FFF !important " }}
                    >
                        Add Module
                    </Button>
                </Stack>

                <Card padding={5}>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    <CardContent >
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 400 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={moduleData?.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filterCoupon?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                            const { id, name, status } = row;
                                            const isItemSelected = selected.indexOf(name) !== -1;
                                            return (
                                                <TableRow
                                                    hover
                                                    // key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <TableCell align="left"> {name ? name : '-'} </TableCell>
                                                    <TableCell align="left" >
                                                        <FormControlLabel
                                                            control={
                                                                <Switch onChange={(e) => toggleActive(e, id)} checked={status === 'ACTIVE' ? true : false} />
                                                            }
                                                            label=""
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left" >
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => AssignScreen(id)}
                                                            color="primary"
                                                        >
                                                            Assign Screeen
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <ModuleMoreMenu
                                                            onEdit={EditModuleStatus}
                                                            id={id}
                                                            data={row}
                                                            removeData={removeModuleData}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={13} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    {isDataNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    </CardContent>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={moduleData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </div >


            <ModalComponent
                width="100%"
                maxWidth="500px"
                open={createModal}
                handleClose={() => handelCloseModal()}
            >
                <Card>
                    <Stack px={2} pt={2} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                        <Stack mt={1}>
                            <h4> {isEdit ? 'Update' : 'Add'} Module Data</h4>
                        </Stack>
                        <form onSubmit={handleCreateUpdateModule}>
                            <Item>
                                <TextField
                                    size="small"
                                    style={{ width: '300px' }}
                                    type="text"
                                    label="Name"
                                    variant="outlined"
                                    value={moduleDataValue?.name}
                                    name="name"
                                    onChange={handleChange}
                                    required
                                />
                            </Item>

                            <Item>
                                <Stack direction="row" spacing={2} justifyContent="flexStart" >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                onChange={(e) => handleSwitch('status', e.target.checked)}
                                                checked={moduleDataValue?.status === 'ACTIVE' ? true : false}
                                                name='status'
                                            />
                                        }
                                        label="Active Status"
                                    />
                                </Stack>
                            </Item>

                            <Item>
                                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type='submit'
                                    // disabled={Object.values(moduleDataValue)?.some((_) => _?.length === 0)}
                                    >
                                        {isEdit ? "Update" : "Add"}
                                    </Button>
                                    <Button variant="contained" onClick={() => handelCloseModal()} color="primary">
                                        Back
                                    </Button>
                                </Stack>
                            </Item>
                        </form>
                    </Stack>
                </Card>
            </ModalComponent >

            {assignModal && <AssignScreenModal moduleId={moduleId} open={assignModal} handleClose={() => handelCloseModal()} />}

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />

        </>

    )
}

export default ModuleMaster