import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';

import Typography from '@mui/material/Typography';

import GlobalStyles from '@mui/material/GlobalStyles';
import { useAccount } from 'context/AccountProvider';
import isEmpty from 'is-empty';

export default function Pricing({ data, onSelect, productList, onSelectForOrgHandler }) {
    const { userDetails } = useAccount();
    console.log(productList, 'productList');
    return (
        <>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            <div className="plans_main">
                <Grid container spacing={5} alignItems="flex-end">
                    {data.map((item) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid item key={item.name} xs={12} md={3}>
                            <Card>
                                <CardHeader
                                    title={item.name}
                                    subheader={item.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    action={item.subheader ? <StarIcon /> : null}
                                    subheaderTypographyProps={{
                                        align: 'center'
                                    }}
                                    sx={{
                                        backgroundColor: (theme) => (theme.palette.mode === 'light' ? '#f0f0f0' : theme.palette.grey[700])
                                    }}
                                    className={item.subheader ? 'most' : ''}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2
                                        }}
                                    >
                                        <span className="duration_text">Duration:</span>
                                        <Typography component="h2" variant="h3" color="#03639d">
                                            {item.num_weeks}
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">
                                            &nbsp;week
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2
                                        }}
                                    >
                                        <span className="duration_text">Plan Type:</span>
                                        <Typography component="h5" variant="h5" color="#03639d">
                                            {item.plan_type}
                                        </Typography>
                                    </Box>
                                    <ul>
                                        {item?.products?.map((p, i) => (
                                            <Typography key={i} component="li" variant="subtitle1" align="center">
                                                {p.name}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={() => {
                                            if (item.plan_type === 'individual') {
                                                return (window.location.href = `/dashboard/invoice?plan=${item.id}&type=${item.plan_type}`);
                                            }
                                            if (userDetails.user_type === 'organisation' && !isEmpty(userDetails.organisation)) {
                                                return onSelectForOrgHandler(item);
                                            }
                                            onSelect(item.id, item.plan_type);
                                        }}
                                    >
                                        Select 
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </>
    );
}
