import axios from 'axios';

export const getInvoiceForPlan = async (id, payload) => {
    try {
        const { data } = await axios.post(`plans/${id}/invoice`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

export const saveInvoiceTemplate = async (payload) => {
    try {
        const { data } = await axios.post(`invoice-template`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

export const getAllTemplate = async () => {
    try {
        const { data } = await axios.get(`invoice-template`);
        return data;
    } catch (err) {
        return err;
    }
};

export const getTemplateById = async (id) => {
    try {
        const { data } = await axios.get(`invoice-template/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

export const updateTemplateById = async (id, payload) => {
    try {
        const { data } = await axios.put(`invoice-template/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

export const deleteTemplateById = async (id) => {
    try {
        const { data } = await axios.delete(`invoice-template/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

//fetch invoice list data

const instance = axios.create({
    baseURL: process.env.REACT_APP_INVOICE_API_URL,
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        if (token) {
            // Set the token in the request headers
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const getInvoiceList = async (organization_id) => {
    try {
        const { data } = await instance.get(`invoices/${organization_id}/list`);
        return data;
    } catch (err) {
        return err;
    }
};

export const createInvoice = async (organization_id, payload) => {
    try {
        const { data } = await instance.post(`invoices/${organization_id}/create`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

export const updateInvoice = async (organization_id, id, payload) => {
    try {
        const { data } = await instance.post(`invoices/${organization_id}/${id}/update`, payload);
        return data;
    } catch (err) {
        return err;
    }
}

export const deleteInvoice = async (organization_id, id) => {
    try {
        const { data } = await instance.delete(`invoices/${organization_id}/${id}/delete`);
        return data;
    } catch (err) {
        return err;
    }
}

// genrate PDF

const instance2 = axios.create({
    baseURL: 'https://dev-file-processor.kronicle.in/'
});

instance2.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        if (token) {
            // Set the token in the request headers
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const genratePdf = async (payload) => {
    try {
        const { data } = await instance2.post(`html-to-pdf-converter`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// preview PDF APi

const instance1 = axios.create({
    baseURL: process.env.REACT_APP_INVOICE_API_URL,
});

instance1.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        if (token) {
            // Set the token in the request headers
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// export const genratePdf = async (payload) => {
//     try {
//         const { data } = await instance1.post(`html-to-pdf-converter`, payload);
//         return data;
//     } catch (err) {
//         return err;
//     }
// }

export const getDefuleInvoice = async () => {
    try {
        const { data } = await axios.get(`invoice-template-default`);
        return data;
    } catch (err) {
        return err;
    }
};

// invoice-billing/list for timesheet data in invoice form

export const getTimeSheetData = async (payload) => {
    try {
        const { data } = await axios.post(`invoice-billing/list`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// invoices/1234/6576724dd12164a16a4eb4f0/read
export const getInvoiceById = async (orgID, id) => {
    try {
        const { data } = await instance1.get(`invoices/${orgID}/${id}/read`);
        return data;
    } catch (err) {
        return err;
    }
};
