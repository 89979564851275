import React, { useState } from 'react'
import {
    Card,
    Table,
    Stack,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    Avatar,
} from '@mui/material';
import Toast from 'components/Toast';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { assignOrgDepartmentToRole, assignOrgPracticeAreaToRole, deleteAssignPracArea, deleteRoleModule, getAllOrgRoleModule, getAssignRoleToPracArea } from 'services/UserAccessMasterService';
import defultPlholder from 'assets/images/defaultImg.png';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { useAccount } from 'context/AccountProvider';





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'name', label: 'Module Name', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) =>
            _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


function AssignPracticeAreaToRole({ id, handleClose, open }) {
    const { userDetails } = useAccount();
    const [orgDesignationData, setOrgDesignationData] = useState([])
    const [assignedScreenRole, setAssignedScreenRole] = useState([])
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    useEffect(() => {
        fetchAssignOrgRole()
        fetchOrgRole()
    }, [])


    const fetchOrgRole = async () => {
        const res = await getAllOrgRoleModule();

        if (res?.data) {
            setOrgDesignationData(res?.data)
        }
        else {
            setOrgDesignationData([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    const fetchAssignOrgRole = async () => {
        const res = await getAssignRoleToPracArea(userDetails.organisation.id, id);

        if (res?.data) {
            setAssignedScreenRole(res?.data)
            let moduleIds = res?.data.map(item => item.role);
            setSelected(moduleIds);
        }
        else {
            setAssignedScreenRole([])
            // setToastData({
            //     open: true,
            //     status: 'error',
            //     message: res.response.data.message || 'Something went wrong'
            // });
        }
    }

    const handleSelectedData = () => {
        const screenIds = assignedScreenRole?.map(item => item.screen.id);
        setSelected(screenIds);
    }



    //on check unchecked assign plan to user function
    const handleClick = async (event, roleId) => {

        if (event.target.checked === true) {
            const payload = {
                practice_area: id,
                role: roleId,
                status: "ACTIVATE"
            };
            const res = await assignOrgPracticeAreaToRole(userDetails.organisation.id, payload);

            if (res?.response?.status === 400) {
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
            }
            else {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignOrgRole()
            }
        }
        else {
            let RemoveRoleId = assignedScreenRole?.filter((_) => _.role === roleId)[0]?.id
            const res = await deleteAssignPracArea(userDetails.organisation.id, RemoveRoleId);
            if (res.message === "Data deleted successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignOrgRole()
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res.message
                });
            }
        }
    };


    const filteredScreen = applySortFilter(orgDesignationData, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredScreen.length === 0;

    return (
        <>
            <ModalComponent
                width="100%"
                maxWidth="500px"
                open={open}
                handleClose={handleClose}
            >
                <Stack spacing={1}>
                    <Stack mt={1}>
                        <h4> Assign Practice Area To Role </h4>
                    </Stack>

                    <Card>
                        {/* <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} /> */}
                        <TableContainer sx={{ height: "400px" }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={orgDesignationData.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {filteredScreen.map((row) => {
                                        const { name, id } = row;
                                        const isItemSelected = selected.indexOf(id) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                            >
                                                <TableCell align="left">{name}</TableCell>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Card>
                </Stack>
            </ModalComponent>
            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignPracticeAreaToRole