import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Loader from 'ui-component/Loader';
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, TableContainer, TablePagination, TextField, Paper, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, TableHead, Tab } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import { styled } from '@mui/system';
import Toast from 'components/Toast';
import Iconify from 'components/Iconify';
import ModalComponent from 'components/Modal';
import { getAllClient, getMatterByClient } from 'services/ClientService';
import DataListToolbar from '../DataListToolbar';
import DataMoreMenu from '../DataMoreMenu';
import { assignValueToData, createDataRole, deleteDataRole, getDataList, getDataRoleList, getDataRoleValue, getOrgDataItemList, updateDataItem, updateDataRole } from 'services/DataRoleService';
import { getAllMatters } from 'services/MatterService';
import AssignClienMatterPopup from '../AssignClienMatterPopup';
import AssignDesignationPopup from '../AssignDesignationPopup';
import AssignPracticeAreaPopup from '../AssignPracticeAreaPopup';
import AssignDepartmentPopup from '../AssignDepartmentPopup';
import AssignModulePopup from '../AssignModulePopup';
import AssignDataRoleToUserPopup from '../AssignDataRoleToUserPopup';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}


function DataRole() {
    const [list, setList] = useState([])
    const [orgRoleData, setOrgRoleData] = useState([])
    const [IsLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterName, setFilterName] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [createModal, setCreateModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [module, setModule] = useState(false)
    const [userModule, setUserModule] = useState(false)
    const [matterClientModule, setMatterClientModule] = useState(false)
    const [departmentModule, setDepartmentModule] = useState(false)
    const [designationModule, setDesignationModule] = useState(false)
    const [practiceAreaModule, setPracticeAreaModule] = useState(false)

    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });


    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    const [activeDataRoleId, setActiveDataRoleId] = useState('')


    const [orgDataRoleValue, setOrgDataRoleValue] = useState({
        name: '',
        status: 'INACTIVE',
    });

    const [prevdataRoleData, setprevDataRoleData] = useState([])

    const [allClientList, setAllClientList] = useState([])
    const [matterList, setMatterList] = useState([])


    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orgRoleData?.length) : 0;

    const filterCoupon = applySortFilter(orgRoleData, getComparator(order, orderBy), filterName);

    const isDataNotFound = filterCoupon?.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orgRoleData?.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handelCloseModal = () => {
        setOrgDataRoleValue({
            name: '',
            status: 'INACTIVE',
        })
        setModule(false)
        setUserModule(false)
        setMatterClientModule(false)
        setDepartmentModule(false)
        setDesignationModule(false)
        setPracticeAreaModule(false)
        setCreateModal(false)
        setIsEdit(false)
        setClient_matter([
            {
                "client_id": '',
                "matter_id": ''
            }
        ])
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrgDataRoleValue((prev) => ({ ...prev, [name]: value }));
    };


    useEffect(() => {
        getAllMatterHandler()
        fetchAllDataRole()
        getSelectedDataItem()
        fetchAllDataRole()
        fetchAllClient()
    }, [])



    /// fetch org data list
    const getSelectedDataItem = (async () => {
        const res = await getOrgDataItemList();
        if (res.data.length > 0) {
            const values = res.data.map(item => item?.data_access_field?.value);
            setList(values)
        }
    });


    //fetch all module data
    const fetchAllDataRole = async () => {
        const res = await getDataRoleList();
        if (res?.data) {
            setOrgRoleData(res?.data)
        }
        else {
            setOrgRoleData([])
        }
    }


    //fetch all client list
    const fetchAllClient = async () => {
        const res = await getAllClient();
        if (res) { setAllClientList(res) }
        else { setAllClientList([]) }
    }


    // get client wise matter list 
    const getAllMatterHandler = async () => {
        const res = await getAllMatters();
        console.log(res, 'resMatter');
        if (res && res.length > 0) {
            setMatterList(res);
        } else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || 'Something went wrong'
            });
        }
    };


    //find changes
    const findChangedData = (previousObj, currentObj) => {
        const changedData = {};
        for (const key in previousObj) {
            if (previousObj[key] !== currentObj[key]) {
                changedData[key] = currentObj[key];
            }
        }
        return changedData;
    };


    //handle create uodate data role 
    const handleCreateUpdateData = async (event) => {
        event.preventDefault()
        let payload = { ...orgDataRoleValue }
        if (isEdit) {
            const newChangedKeys = findChangedData(prevdataRoleData, orgDataRoleValue);
            const res = await updateDataRole(orgDataRoleValue?.id, newChangedKeys);
            if (res?.message === "Organization Data role updated successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchAllDataRole();
                setIsEdit(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || 'Something went wrong'
                });
            }
        }
        else {
            const res = await createDataRole(payload);
            if (res.message === "Organization Data Role created successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchAllDataRole();
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res?.message || 'Something went wrong'
                });
            }

        }
    }




    // handle Switch
    const handleSwitch = (name, value) => {
        let status
        if (value) {
            status = 'ACTIVE';
        } else {
            status = 'INACTIVE';
        }
        setOrgDataRoleValue((prev) => ({ ...prev, [name]: status }));
    }

    //handle table toggle 
    const toggleActive = async (e, id) => {
        const tempScreenData = [...orgRoleData];
        tempScreenData.forEach(async (el) => {
            console.log("ël", el);
            if (el.id === id) {
                if (e.target.checked) {
                    el.status = 'ACTIVE';
                } else {
                    el.status = 'INACTIVE';
                }
                const payload = {
                    status: el.status,
                };
                const res = await updateDataRole(id, payload);
                console.log(res, 'resData');
                debugger
                if (res.message === "Organization Data role updated successfully.") {
                    setToastData({
                        open: true,
                        status: 'success',
                        message: res?.message || 'Something went wrong'
                    });
                    fetchAllDataRole();
                }
                else {
                    fetchAllDataRole();
                    setToastData({
                        open: true,
                        status: 'error',
                        message: res?.response?.data?.message || 'Something went wrong'
                    });
                }
            }
        });

    };

    // handle remove data role 
    const removeOrgDataRole = async (data) => {
        if (data?.status === 'INACTIVE') {
            const res = await deleteDataRole(data.id);
            if (res.message === "Organization data role deleted successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                fetchAllDataRole();
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res?.message || 'Something went wrong'
                });
            }
        }
        else {
            setToastData({
                open: true,
                status: 'warning',
                message: `To Delete, First Deactivate The Role Status.`
            });
        }
    }

    // edit data role
    const EditDataStatus = (id, data) => {
        setIsEdit(true)
        setCreateModal(true)
        setOrgDataRoleValue(data)
        setprevDataRoleData(data)
    }

    const [client_matter, setClient_matter] = useState([
        {
            "client_id": '',
            "matter_id": ''
        }
    ])


    return (
        <>
            {console.log("client_matter", client_matter)}
            {IsLoading && <Loader />}
            <div className='country'>
                <Stack direction="row" justifyContent="flex-start" mb={2}>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="#"
                        onClick={() => setCreateModal(!createModal)}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        sx={{ color: "#FFF !important " }}
                    >
                        Create Data Role
                    </Button>
                </Stack>

                <Card padding={5}>
                    <DataListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    {/* <CardContent > */}
                    <Scrollbar>
                        <TableContainer>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={orgRoleData?.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filterCoupon?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                        const { id, name, status } = row;
                                        const isItemSelected = selected.indexOf(name) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                // key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell align="left"> {name ? name : '-'} </TableCell>
                                                <TableCell align="left" >
                                                    <FormControlLabel
                                                        control={
                                                            <Switch onChange={(e) => toggleActive(e, id)} checked={status === 'ACTIVE' ? true : false} />
                                                        }
                                                        label=""
                                                    />
                                                </TableCell>
                                                <TableCell align="left" >
                                                    <Stack gap={1} direction={'row'}>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                setActiveDataRoleId(id)
                                                                setUserModule(true)
                                                            }}
                                                            color="primary"
                                                        >
                                                            Assign To User
                                                        </Button>

                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                setActiveDataRoleId(id)
                                                                setModule(true)
                                                            }}
                                                            color="primary"
                                                        >
                                                            Assign Module
                                                        </Button>

                                                        {list?.includes('client_matter') &&
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => {
                                                                    setActiveDataRoleId(id)
                                                                    setMatterClientModule(true)
                                                                }}
                                                                color="primary"
                                                            >
                                                                Assign Client & Matter
                                                            </Button>
                                                        }

                                                        {list?.includes('department') &&
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => {
                                                                    setActiveDataRoleId(id)
                                                                    setDepartmentModule(true)
                                                                }}
                                                                color="primary"
                                                            >
                                                                Assign Department
                                                            </Button>
                                                        }
                                                        {list?.includes('designation') &&
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => {
                                                                    setActiveDataRoleId(id)
                                                                    setDesignationModule(true)
                                                                }}
                                                                color="primary"
                                                            >
                                                                Assign Designation
                                                            </Button>
                                                        }
                                                        {list?.includes('practise_area') &&
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => {
                                                                    setActiveDataRoleId(id)
                                                                    setPracticeAreaModule(true)
                                                                }}
                                                                color="primary"
                                                            >
                                                                Assign Practice Area
                                                            </Button>
                                                        }
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="right">
                                                    <DataMoreMenu
                                                        onEdit={EditDataStatus}
                                                        id={id}
                                                        data={row}
                                                        removeData={removeOrgDataRole}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={13} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isDataNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    {/* </CardContent> */}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={orgRoleData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </div >


            <ModalComponent
                width="calc(100% - 40px)"
                maxWidth="500px"
                open={createModal}
                handleClose={() => handelCloseModal()}
            >
                <Card>
                    <Stack px={2} pt={2} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                        <Stack mt={1}>
                            <h4>{isEdit ? 'Update' : 'Add'} Data Role</h4>
                        </Stack>
                        <form
                            onSubmit={handleCreateUpdateData}
                        >
                            <Item>
                                <TextField
                                    size="small"
                                    style={{ width: '300px' }}
                                    type="text"
                                    label="Name"
                                    variant="outlined"
                                    value={orgDataRoleValue?.name}
                                    name="name"
                                    onChange={handleChange}
                                    required
                                />
                            </Item>
                            <Item>
                                <Stack direction="row" spacing={2} justifyContent="flexStart" >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                onChange={(e) => handleSwitch('status', e.target.checked)}
                                                checked={orgDataRoleValue?.status === 'ACTIVE' ? true : false}
                                                name='status'
                                            />
                                        }
                                        label="Active Status"
                                    />
                                </Stack>
                            </Item>

                            <Item>
                                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type='submit'
                                    // disabled={Object.values(orgDataRoleValue)?.some((_) => _?.length === 0)}
                                    >
                                        {isEdit ? "Update" : "Add"}
                                    </Button>
                                    <Button variant="contained" onClick={() => handelCloseModal()} color="primary">
                                        Back
                                    </Button>
                                </Stack>
                            </Item>
                        </form>
                    </Stack>
                </Card>
            </ModalComponent >




            {userModule &&
                <AssignDataRoleToUserPopup
                    open={userModule}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                />
            }


            {module &&
                <AssignModulePopup
                    open={module}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                />
            }

            {matterClientModule &&
                <AssignClienMatterPopup
                    open={matterClientModule}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                    allClientList={allClientList}
                    allMatterList={matterList}
                />
            }

            {departmentModule &&
                <AssignDepartmentPopup
                    open={departmentModule}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                />
            }

            {designationModule &&
                <AssignDesignationPopup
                    open={designationModule}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                />
            }

            {practiceAreaModule &&
                <AssignPracticeAreaPopup
                    open={practiceAreaModule}
                    handleClose={() => handelCloseModal()}
                    activeDataRoleId={activeDataRoleId}
                />
            }


            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />

        </>
    )
}

export default DataRole