import React, { useEffect, useState } from 'react'
import { Card, CardContent, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import UserModalToolbar from 'views/NewSubscriptions/UserModalToolbar';
import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import { Stack, styled } from '@mui/system';
import Toast from 'components/Toast';
import { useAccount } from 'context/AccountProvider';
import DataListToolbar from '../DataListToolbar';
import { CommonListHead } from 'sections/@dashboard/common';
import { getDataList, getOrgDataItemList, selectDataItem, unSelectDataItem } from 'services/DataRoleService';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: '', label: '', alignRight: false },
    { id: 'label', label: 'Data Name', alignRight: false },
    { id: 'value', label: 'Data Key', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) =>
            _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


function DataRoleList() {

    const { userDetails } = useAccount();
    const [list, setList] = useState([])
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [orderBy, setOrderBy] = useState('first_name');
    const [filterName, setFilterName] = useState('');
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    useEffect(() => {
        getSelectedDataItem();
        fetchDataMasterData()
    }, []);
    const fetchDataMasterData = async () => {
        const res = await getDataList();
        if (res?.data) {
            setList(res?.data)
        }
        else {
            setList([])
            setToastData({
                open: true,
                status: 'success',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    const getSelectedDataItem = (async () => {
        const res = await getOrgDataItemList();
        debugger
        if (res.data.length > 0) {
            setSelectedData(res.data)
            const dataAccessFields = res.data.map(item => item.data_access_field.id);
            // const ids = dataAccessFields.map(item => item.id);
            setSelected(dataAccessFields);
        }
    });


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const filteredDataList = applySortFilter(list, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredDataList.length === 0;

    //on check unchecked assign plan to user function
    const handleClick = async (event, id) => {
        if (event.target.checked === true) {
            const res = await selectDataItem(userDetails?.organisation?.id, id);
            debugger
            if (res?.response?.status === 400) {
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
            }
            else {
                const selectedIndex = selected.indexOf(id);
                let newSelected = [];
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(selected, id);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(selected.slice(1));
                } else if (selectedIndex === selected.length - 1) {
                    newSelected = newSelected.concat(selected.slice(0, -1));
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
                }
                debugger
                setSelected(newSelected);
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                getSelectedDataItem()
            }
        }
        else {
            debugger
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
            setSelected(newSelected);

            const res = await unSelectDataItem(selectedData?.filter((_) => _.data_access_field?.id === id)[0].id);
            debugger


            setToastData({
                open: true,
                status: 'success',
                message: res?.response?.data?.message || res.message
            });
            getSelectedDataItem()
        }


    };


    return (
        <>
            <Card>
                <DataListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 400 }}>
                        <Table>
                            <CommonListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={list.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredDataList.map((row) => {
                                    const { label, value, id } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;

                                    { console.log("isItemSelected", selected, selected.indexOf('2'), id) }
                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                            </TableCell>
                                            <TableCell align="left"> {label} </TableCell>
                                            <TableCell align="left"> {value} </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Card>
            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default DataRoleList