import axios from 'axios';

export const subscription = async (payload) => {
    try {
        const { data } = await axios.post(`subscriptions`, payload);
        return data;
    } catch (err) {
        return err;
    }
};
export const getSubscription = async () => {
    try {
        const { data } = await axios.get(`subscriptions`);
        return data;
    } catch (err) {
        return err;
    }
};
export const getSubscriptionDetails = async (id) => {
    try {
        const { data } = await axios.get(`subscriptions/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};
export const getUsersByProduct = async (id) => {
    try {
        const { data } = await axios.get(`products/${id}/users`);
        return data;
    } catch (err) {
        return err;
    }
};




// for assign subscription to user 
export const assignSubscriptionToUser = async (userId, payload) => {
    try {
        const { data } = await axios.post(`${userId}/assigned-subscription`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//for get assign subscription of user
export const getAssignedSubscriptionOfUser = async (orgID, userId, SubscriptionID) => {
    try {
        // for perticular userid and subscriptionid 
        // const { data } = await axios.put(`/assigned-subscription/${orgID}?user_id=${userId}&subscription_id=${SubscriptionID}`);
        const { data } = await axios.get(`/assigned-subscription/${orgID}`);
        return data;
    } catch (err) {
        return err;
    }
};

//for unassigned subscription
export const unAssigneSubscriptionOfUser = async (userId, SubscriptionID) => {
    try {
        const { data } = await axios.delete(`${userId}/assigned-subscription/${SubscriptionID}`);
        return data;
    } catch (err) {
        return err;
    }
};