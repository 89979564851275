// expense/<str:organization_id>/<str:expense_id>/read
// expense/<str:organization_id>/<str:expense_id>/update
// expense/<str:organization_id>/list
// expense/<str:organization_id>/<str:expense_id>/delete

// expense/<str:organization_id>/create
// url/expense-category-master
// ur/expense-category-master/id
// url/expense-category-master/id
// url/expense-category-master/id
// url/expense-category-master
import axios from 'axios';


const instance = axios.create({
    baseURL: process.env.REACT_APP_INVOICE_API_URL,
});

const getExpanseRead = async (organization_id, expense_id) => {
    try {
        const { data } = await instance.get(`expense/${organization_id}/${expense_id}/read`);
        return data;
    } catch (err) {
        return err;
    }
}

const postExpanseUpdate = async (organization_id, expense_id, payload) => {
    try {
        const { data } = await instance.post(`expense/${organization_id}/${expense_id}/update`, payload);
        return data;
    } catch (err) {
        return
    }
}

const getExpanseList = async (organization_id) => {
    try {
        const { data } = await instance.get(`expense/${organization_id}/list`);
        return data;
    } catch (err) {
        return err;
    }
}
const getExpanseListClientMatter = async (organization_id, clientId, matterId, startDate, endDate) => {
    try {
        const { data } = await instance.get(`expense/${organization_id}/client/${clientId}/matter/${matterId}?start_datetime=${startDate}&end_datetime=${endDate}`);
        return data;
    } catch (err) {
        return err;
    }
}

const deleteExpanse = async (organization_id, expense_id) => {
    try {
        const { data } = await instance.delete(`expense/${organization_id}/${expense_id}/delete`);
        return data;
    } catch (err) {
        return err;
    }
}


const postExpanseCreate = async (organization_id, payload) => {
    try {
        const { data } = await instance.post(`expense/${organization_id}/create`, payload);
        return data;
    } catch (err) {
        return err;
    }
}

const getExpenseCategoryMaster = async () => {
    try {
        const { data } = await instance.get(`expense-category-master`);
        return data;
    } catch (err) {
        return
    }
}

const getExpenseCategoryMasterById = async (id) => {
    try {
        const { data } = await instance.get(`expense-category-master/${id}`);
        return data;
    } catch (err) {
        return err;
    }
}

const getExpenseCategoryMasterUpdate = async (id, payload) => {
    try {
        const { data } = await instance.post(`expense-category-master/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
}

const deleteExpenseCategoryMasterDelete = async (id) => {
    try {
        const { data } = await instance.delete(`expense-category-master/${id}`);
        return data;
    } catch (err) {
        return err;
    }
}

const getExpenseCategoryMasterCreate = async (payload) => {
    try {
        const { data } = await instance.post(`expense-category-master`, payload);
        return data;
    } catch (err) {
        return err;
    }
}

export {
    getExpanseRead,
    postExpanseUpdate,
    getExpanseList,
    deleteExpanse,
    postExpanseCreate,
    getExpenseCategoryMaster,
    getExpenseCategoryMasterById,
    getExpenseCategoryMasterUpdate,
    deleteExpenseCategoryMasterDelete,
    getExpenseCategoryMasterCreate,
    getExpanseListClientMatter
}