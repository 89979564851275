import React, { useState } from 'react'
import {
    Card,
    Table,
    Stack,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    Avatar,
} from '@mui/material';
import Toast from 'components/Toast';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { assignScreenToModule, getAllScreen, getAssignScreenOfModule, getScreenAllAction, unAssignScreenFromModule } from 'services/UserAccessMasterService';
import defultPlholder from 'assets/images/defaultImg.png';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import AssignScreenActionModal from './AssignScreenActionModal';





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'descriptive_name', label: 'Screen Name', alignRight: false },
    { id: '', label: 'Status', alignRight: false },
    { id: '', label: 'Screen Action', alignRight: false },
    { id: '', label: 'Assign/UnAssign', alignRight: true },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) =>
            _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


function AssignScreenModal({ moduleId, handleClose, open }) {

    
    const [screenData, SetScreenData] = useState([])
    const [assignedScreen, SetAssignedScreen] = useState([])
    const [screenId, SetScreenId] = useState('')
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');

    const [actionModle, setActionModle] = useState(false)


    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    useEffect(() => {
        fetchAssignScreenOfmodule()
        fetchScreenData()
    }, [])


    const fetchScreenData = async () => {
        const res = await getAllScreen();
        if (res?.data) {
            SetScreenData(res?.data)
        }
        else {
            SetScreenData([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    const fetchAssignScreenOfmodule = async () => {
        const res = await getAssignScreenOfModule(moduleId);
        
        if (res?.data) {
            SetAssignedScreen(res?.data)
            let screenIds = res?.data.map(item => item.screen.id);
            setSelected(screenIds);
        }
        else {
            SetAssignedScreen([])
            // setToastData({
            //     open: true,
            //     status: 'error',
            //     message: res.response.data.message || 'Something went wrong'
            // });
        }
    }


    //on check unchecked assign plan to user function
    const handleClick = async (event, screenId) => {
        if (event.target.checked === true) {
            const payload = {
                status: "ACTIVATE"
            };
            const res = await assignScreenToModule(screenId, moduleId, payload);
            
            if (res?.response?.status === 400) {
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
            }
            else {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignScreenOfmodule()
            }
        }
        else {
            let ScreenID = assignedScreen?.filter((_) => _.screen.id === screenId)[0].id

            const res = await unAssignScreenFromModule(ScreenID);

            
            if (res.message === 'Screen Module mapping deleted successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignScreenOfmodule()
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res.message
                });
            }
        }
    };


    const filteredScreen = applySortFilter(screenData, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredScreen.length === 0;




    ///Screen Action Functions 

    const handleViewScreenAction = async (id) => {
        setActionModle(true)
        SetScreenId(id)
    }


    const handleActionModulClose = () => {
        setActionModle(false)
    }



    return (
        <>
            <ModalComponent
                width="100%"
                maxWidth="700px"
                open={open}
                handleClose={handleClose}
            >
                <Stack spacing={1}>

                    {!actionModle ?
                        <>
                            <Stack mt={1}>
                                <h4>Assign Screen to module</h4>
                            </Stack>

                            <Card>
                                {/* <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} /> */}
                                <TableContainer sx={{ height: "400px" }}>
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={screenData.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                        />
                                        <TableBody>
                                            {filteredScreen.map((row) => {
                                                const { descriptive_name, id, status } = row;
                                                const isItemSelected = selected.indexOf(id) !== -1;
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={id}
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell align="left">{descriptive_name}</TableCell>
                                                        <TableCell align="left">{status}</TableCell>
                                                        <TableCell padding="checkbox">
                                                            <Button variant="contained"
                                                                sx={{ width: "110px" }}
                                                                onClick={() => handleViewScreenAction(id)}
                                                            >
                                                                View Action
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell padding="checkbox" align='center'>
                                                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>

                                        {isUserNotFound && (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                                        <SearchNotFound searchQuery={filterName} />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Card>
                        </>
                        :
                        <>

                            <AssignScreenActionModal moduleID={moduleId} id={screenId} onBack={() => handleActionModulClose()} />
                            {/* <Stack mt={1}>
                                <h4>Assign ScreenAction to Module</h4>
                            </Stack>

                            <Card>
                                <TableContainer sx={{ height: "400px" }}>
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={ACTION_TABLE_HEAD}
                                            rowCount={screenAction.length}
                                            numSelected={screenAction.length}
                                            onRequestSort={handleRequestSort}
                                        />
                                        <TableBody>
                                            {screenAction.length ?
                                                screenAction.map((row) => {
                                                    const { action, status, id } = row;
                                                    const isItemSelected = selected.indexOf(id) !== -1;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={id}
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell align="left">{action}</TableCell>
                                                            <TableCell align="left">{status}</TableCell>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                                :
                                                <TableRow  >
                                                    <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                                        <h6> No Action Found </h6>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                            <Item>
                                <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                                    <Button variant="contained"
                                        onClick={() => handleActionModulClose()}
                                        color="primary">
                                        Back
                                    </Button>
                                </Stack>
                            </Item> */}
                        </>
                    }
                </Stack>
            </ModalComponent>









            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignScreenModal