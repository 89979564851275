import React, { useState, useEffect } from 'react'
import {
    Card,
    Table,
    Stack,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Toast from 'components/Toast';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { getAllModule } from 'services/UserAccessMasterService';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { useAccount } from 'context/AccountProvider';
import { assignModuleToDataRole, getAssignModuleToDataRole, unAssignModuleToDataRole } from 'services/DataRoleService';
import id from 'date-fns/locale/id';
import Loader from 'ui-component/Loader';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'name', label: 'Module Name', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) =>
            _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


function AssignModulePopup({ open, handleClose, activeDataRoleId }) {
    const { userDetails } = useAccount();
    const [IsLoading, setIsLoading] = useState(false)
    const [moduleData, setModuleData] = useState([])
    const [assignedModule, setAssignedModule] = useState([])
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('first_name');
    const [filterName, setFilterName] = useState('');

    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    useEffect(() => {
        fetchAssignValueOfDataRole()
        fetchDepartmentData()
    }, [])

    const fetchAssignValueOfDataRole = async () => {
        const res = await getAssignModuleToDataRole(userDetails.organisation.id, activeDataRoleId);
        debugger
        if (res.data) {
            setAssignedModule(res?.data)
            if (res?.data.length) {
                let assignModuleIds = res?.data?.map(item => item?.module)
                setSelected(assignModuleIds)
            }
            else {
                setSelected([])
            }
        }
        else { setAssignedModule([]) }
    }

    const fetchDepartmentData = async () => {
        const res = await getAllModule();
        if (res.data) {
            setModuleData(res.data)
        }
        else {
            setModuleData([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }


    //on check unchecked assign plan to user function
    const handleClick = async (event, moduleId) => {
        if (event.target.checked === true) {
            setIsLoading(true)
            const payload = {
                role: activeDataRoleId,
                module: moduleId,
                status: "ACTIVATE"
            };
            const res = await assignModuleToDataRole(userDetails.organisation.id, payload);
            debugger

            if (res?.response?.status === 400) {
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
                setIsLoading(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignValueOfDataRole()
                setIsLoading(false)
            }
        }
        else {
            debugger
            setIsLoading(true)
            const filteredDataId = assignedModule?.filter(item => item?.module === moduleId)[0].id;
            const res = await unAssignModuleToDataRole(userDetails.organisation.id, filteredDataId);
            if (res.message === "Data deleted successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message    
                });
                fetchAssignValueOfDataRole()
                setIsLoading(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res.message
                });
                setIsLoading(false)
            }
        }
    };


    const filteredScreen = applySortFilter(moduleData, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredScreen?.length === 0;

    return (
        <>
            {IsLoading && <Loader />}
            <ModalComponent
                width="100%"
                maxWidth="500px"
                open={open}
                handleClose={handleClose}
            >
                <Stack spacing={1}>
                    <Stack mt={1}>
                        <h4> Assign Module To Data Role </h4>
                    </Stack>

                    <Card>
                        {/* <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} /> */}
                        <TableContainer sx={{ height: "400px" }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={moduleData?.length}
                                    numSelected={selected?.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {filteredScreen.map((row) => {
                                        const { name, id } = row;
                                        const isItemSelected = selected?.indexOf(id) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                            >
                                                <TableCell align="left">{name}</TableCell>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Card>
                </Stack>
            </ModalComponent>
            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignModulePopup