/* eslint-disable no-else-return */

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
// import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as React from 'react';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    TextField,
    FormControlLabel,
    Switch,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TableHead,
    Radio,
    RadioGroup,
    FormLabel
} from '@mui/material';
// components
// import Page from '../../../../components/Page';
import Label from '../..';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
// mock
import USERLIST from '../../_mock/user';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useEffect } from 'react';

import { useState } from 'react';
import { addProductToPlan, createPlan, getAllPlans, getProductsByPlan, getProductsLinkToPlan, updatePlan } from 'services/PlanService';
import ModalComponent from 'components/Modal';
import { getAllProducts } from 'services/ProductService';
import { useCallback } from 'react';
import { Box } from '@mui/system';
import { useAccount } from 'context/AccountProvider';
import { createUserOrganization } from 'services/UserService';
import Pricing from './Pricing';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { getSubscription } from 'services/SubscriptionService';
import WarningIcon from '@mui/icons-material/Warning';
// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'name', label: 'Plan Name', alignRight: false },
    { id: 'num_weeks', label: 'Number of Weeks', alignRight: false },
    { id: 'monthly_price ', label: 'Monthly Price', alignRight: false },
    { id: 'annual_price ', label: 'Annual Price', alignRight: false },
    { id: 'plan_level_discount ', label: 'Plan Level Discount', alignRight: false },
    { id: 'max_discount ', label: 'Max Discount', alignRight: false },
    { id: 'is_active ', label: 'Is Active', alignRight: false },
    { id: 'action', label: 'Product Add/View', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function SelectPlans() {
    const { userDetails } = useAccount();
    const navigate = useNavigate();
    // console.log(JSON.stringify(USERLIST))
    const [page, setPage] = React.useState(0);

    const [order, setOrder] = React.useState('asc');

    const [selected, setSelected] = React.useState([]);

    const [orderBy, setOrderBy] = React.useState('name');

    const [filterName, setFilterName] = React.useState('');

    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isAdd, setIsAdd] = React.useState(false);
    const [name, setName] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [contact, setContact] = React.useState('');
    const [mobileNumber, setNumber] = React.useState('');
    const [matters, setMatters] = React.useState('');
    const [UD, setUD] = React.useState([]);
    const [allPlans, setAllPlans] = useState([]);
    const [planDetails, setplanDetails] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [openSelectModal, setOpenSelectModal] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [productDetails, setProductDetails] = useState({
        product_discount: '',
        product: '',
        planId: ''
    });
    const [selectedPlanId, setSelectedPlanId] = useState('');
    const [linkedProductList, setLinkedProductList] = useState([]);
    const [isForOrg, setForOrg] = useState(false);
    const [openModalCreateOrg, setOpenModalCreateOrg] = useState(false);
    const [orgDetails, setOrgDetails] = useState({});
    const [planType, setPlanType] = useState('');
    const [productList, setProductList] = useState([]);
    const [openModalNumUsers, setOpenModalNumUsers] = useState(false);
    const [planForUsers, setPlanForUsers] = useState({
        user: 1,
        type: ''
    });
    const [startDate, setStartDate] = useState(new Date());
    const [dateValue, setDateValue] = useState();
    const [subsList, setSubsList] = useState([]);
    const [warningModal, setWarningModal] = useState(false);
    const [warningModalData, setWarningModalData] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({})


    const handleCloseModalNumUsers = () => setOpenModalNumUsers(false);
    const handleOpenModalNumUsers = () => setOpenModalNumUsers(true);

    const handlePlanTypeChange = (e) => {
        setPlanType(e.target.value);
    };

    const handleChangeOrgDetails = (e) => {
        const { name, value } = e.target;
        setOrgDetails((prev) => ({ ...prev, [name]: value }));
    };

    const handleCloseModalCreateOrg = () => setOpenModalCreateOrg(false);
    const handleOpenModalCreateOrg = () => setOpenModalCreateOrg(true);

    const handleDateChange = (date) => {
        console.log(date, 'date');
        setDateValue(date);
        setStartDate(moment(date).format('YYYY-MM-DD'));
    };

    const handleChangeForOrg = (e) => {
        if (e.target.checked) {
            setForOrg(true);
        } else {
            setForOrg(false);
        }
    };

    const handleCloseModal = () => setOpenModal(false);
    const handleOpenModal = (id, planType) => {
        setProductDetails((prev) => ({ ...prev, planId: id, planType }));
        setSelectedPlanId(id);
        setOpenModal(true);
    };

    const handleCloseSelectModal = (event, reason) => {
        if (reason === 'backdropClick') {
            console.log(reason);
        } else {
            setOpenSelectModal(false);
        }
    };
    const handleOpenSelectModal = () => setOpenSelectModal(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setplanDetails((prev) => ({ ...prev, [name]: value }));
    };
    const handleProductChange = (e) => {
        const { name, value } = e.target;
        setProductDetails((prev) => ({ ...prev, [name]: value }));
    };

    const getAllPlansHandler = useCallback(async () => {
        const res = await getAllPlans();
        console.log(res);
        if (res.length > 0) {
            // res.forEach((el) => {
            //     getProductsByPlanHanlder(el.id);
            // });\
            setAllPlans(res.filter((p) => p.list_public && p.is_active && p.plan_type === userDetails?.user_type && p.products.length > 0));
        }
    }, [userDetails?.user_type]);

    const getProductsLinkToPlanHanlder = useCallback(async () => {
        const res = await getProductsLinkToPlan(selectedPlanId);
        setLinkedProductList(res);
    }, [selectedPlanId]);

    useEffect(() => {
        if (selectedPlanId) {
            getProductsLinkToPlanHanlder();
        }
    }, [selectedPlanId, getProductsLinkToPlanHanlder]);
    useEffect(() => {
        if (userDetails?.user_type) {
            getAllPlansHandler();
        }
    }, [userDetails.user_type, getAllPlansHandler]);

    const addPlansHandler = async () => {
        const { name, num_weeks, annual_price, monthly_price, max_discount, plan_level_discount } = planDetails;
        const payload = {
            name,
            num_weeks,
            annual_price,
            monthly_price,
            is_active: false,
            max_discount,
            plan_level_discount
        };
        const res = await createPlan(payload);
        console.log(res);
        getAllPlansHandler();
        setIsAdd(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - UD.length) : 0;

    const filteredPlans = applySortFilter(allPlans, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredPlans.length === 0;
    const addClient = () => {
        setName('');
        setAddress('');
        setNumber('');
        setContact('');
        setMatters('');
        setIsAdd(!isAdd);
    };
    const handleBack = () => {
        setIsAdd(!isAdd);
    };

    const getAllProductsHandler = async () => {
        const res = await getAllProducts();
        console.log(res);
        setAllProducts(res);
    };

    useEffect(() => {
        getAllProductsHandler();
        getSubscriptionHandler()
    }, []);

    const addProductToPlanHandler = async () => {
        const { planId, product, product_discount } = productDetails;
        const payload = {
            product_discount
        };
        const res = await addProductToPlan(planId, product, payload);
        console.log(res);

        getProductsLinkToPlanHanlder();
    };

    const togglePlanActive = async (e, id) => {
        const tempPlans = [...allPlans];
        let isActive = false;

        tempPlans.forEach(async (el) => {
            if (el.id === id) {
                if (e.target.checked) {
                    el.is_active = true;
                    isActive = true;
                } else {
                    isActive = false;
                    el.is_active = false;
                }
                const payload = {
                    name: el.name,

                    annual_price: el.annual_price,
                    monthly_price: el.monthly_price,
                    is_active: el.is_active,
                    plan_level_discount: el.plan_level_discount,
                    max_discount: el.max_discount,
                    num_weeks: el.num_weeks
                };
                const res = await updatePlan(id, payload);
                console.log(res, 'resData');
            }
        });

        setAllPlans(tempPlans);
    };

    const addOrganizationHandler = async () => {
        const { name, address, email, phone, contact_person_name, gst_number } = orgDetails;
        const payload = {
            name,
            address,
            email,
            phone,
            contact_person_name,
            gst_number
        };
        let res = await createUserOrganization(payload);
        window.location.href = `/dashboard/invoice?plan=${productDetails.planId}&type=${productDetails.planType}`;
        console.log(res);
    };

    // useEffect(() => {
    //     handleOpenSelectModal();
    // }, []);

    const handleBackdropClick = (event) => {
        //these fail to keep the modal open
        event.stopPropagation();
        return false;
    };

    const onFilterByUserType = () => {
        const tempPlans = [...allPlans];
        if (planType) {
            setAllPlans(tempPlans.filter((plans) => plans.plan_type === planType));
            handleCloseSelectModal();
        }
    };

    const getProductsByPlanHanlder = async (id) => {
        const res = await getProductsByPlan(id);
        if (res.length > 0) {
            setProductList(res);
        }
        // return res.length > 0 ? res : [];
    };

    const selectPlanForOrgHandler = () => {
        window.location.href = `/dashboard/invoice?plan=${planForUsers.id}&type=${planForUsers.type}&users=${planForUsers.user}&startDate=${startDate}`;
    };

    const getSubscriptionHandler = async () => {
        const res = await getSubscription();
        setSubsList(res);
    };

    const onSelectForOrgHandler = (data) => {
        setSelectedPlan(data)
        let subscriptionsActiveList = subsList.filter(item => item.plan.id === data.id && new Date(item.end_date) >= new Date())
        if (subscriptionsActiveList.length) {
            setWarningModalData(subscriptionsActiveList)
            setWarningModal(true)
        }
        else {
            setPlanForUsers((prev) => ({ ...prev, type: data.plan_type, id: data.id }));
            handleOpenModalNumUsers();
        }
    }
    const handleWarningnModalConfirm = () => {
        setWarningModal(false)
        setPlanForUsers((prev) => ({ ...prev, type: selectedPlan.plan_type, id: selectedPlan.id }));
        handleOpenModalNumUsers();
    }

    const handleCloseWarningModal = () => {
        setWarningModal(false)
        setSelectedPlan({})
    }

    function mainBody() {
        if (!isAdd) {
            return (
                // <Page title="User">
                <>
                    <Container>
                        <Pricing
                            data={allPlans}
                            productList={productList}
                            onSelect={handleOpenModal}
                            onSelectForOrgHandler={onSelectForOrgHandler}
                        />
                        {/* <div className="con-items ">
                            {allPlans.map((item) => (
                                <div className="item item1">
                                    <div className="con-img"></div>
                                    <header>
                                        <h3>{item.name}</h3>
                                        <p>
                                            <b>duration</b>
                                        </p>
                                    </header>
                                    <ul>
                                        <li>Product one</li>
                                        <li>Product two</li>
                                    </ul>
                                    <button onClick={() => handleOpenModal(item.id)}>Choose Plan</button>
                                </div>
                            ))}
                        </div> */}
                    </Container>

                    <ModalComponent width="900" open={openModal} handleClose={handleCloseModal}>
                        <Card>
                            <Stack padding={5} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                                <h5 style={{ textAlign: 'center' }}>You don't have any organization yet!</h5>
                                <p style={{ marginTop: 10, marginBottom: 10 }}></p>
                                <Button variant="contained" onClick={() => navigate('/dashboard/create-organization')} color="primary">
                                    Create Organization
                                </Button>
                            </Stack>
                        </Card>
                    </ModalComponent>

                    <ModalComponent width="900" open={openModalNumUsers} handleClose={handleCloseModalNumUsers}>
                        <Card>
                            <Stack padding={5} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                                {/* <h4>Plan for Number of Users</h4> */}
                                <Item>
                                    <TextField
                                        size="small"
                                        style={{ width: '300px' }}
                                        type="text"
                                        label="Number of Users"
                                        variant="outlined"
                                        onChange={(e) => setPlanForUsers((prev) => ({ ...prev, user: e.target.value }))}
                                    />
                                </Item>
                                <Item>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Start Date"
                                            inputFormat="MM/DD/YYYY"
                                            value={dateValue}
                                            disablePast={true}
                                            onChange={handleDateChange}
                                            renderInput={(params) => <TextField style={{ width: '300px' }} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Item>
                                <Item>
                                    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                        <Button variant="contained" onClick={selectPlanForOrgHandler} color="primary">
                                            Submit
                                        </Button>
                                    </Stack>
                                </Item>
                            </Stack>
                        </Card>
                    </ModalComponent>

                    {/* create organization modal */}
                    <ModalComponent width="900" open={openModalCreateOrg} handleClose={handleCloseModalCreateOrg}>
                        <form>
                            <Card>
                                <Stack direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                                    <h4>Create Organization</h4>
                                    <Item>
                                        <TextField
                                            size="small"
                                            style={{ width: '300px' }}
                                            type="text"
                                            label="Organization Name"
                                            variant="outlined"
                                            value={orgDetails?.name}
                                            name="name"
                                            onChange={handleChangeOrgDetails}
                                        />
                                    </Item>
                                    <Item>
                                        <TextField
                                            size="small"
                                            style={{ width: '300px' }}
                                            type="text"
                                            label="Address"
                                            variant="outlined"
                                            value={orgDetails?.address}
                                            name="address"
                                            onChange={handleChangeOrgDetails}
                                        />
                                    </Item>
                                    <Item>
                                        <TextField
                                            size="small"
                                            style={{ width: '300px' }}
                                            type="email"
                                            label="Email"
                                            variant="outlined"
                                            value={orgDetails?.email}
                                            name="email"
                                            onChange={handleChangeOrgDetails}
                                        />
                                    </Item>
                                    <Item>
                                        <TextField
                                            size="small"
                                            style={{ width: '300px' }}
                                            type="text"
                                            label="Phone Number"
                                            variant="outlined"
                                            value={orgDetails?.phone}
                                            name="phone"
                                            onChange={handleChangeOrgDetails}
                                        />
                                    </Item>
                                    <Item>
                                        <TextField
                                            size="small"
                                            style={{ width: '300px' }}
                                            type="text"
                                            label="GST Number"
                                            variant="outlined"
                                            value={orgDetails?.gst_number}
                                            name="gst_number"
                                            onChange={handleChangeOrgDetails}
                                        />
                                    </Item>
                                    <Item>
                                        <TextField
                                            size="small"
                                            style={{ width: '300px' }}
                                            type="text"
                                            label="Contact Person Name"
                                            variant="outlined"
                                            value={orgDetails?.contact_person_name}
                                            name="contact_person_name"
                                            onChange={handleChangeOrgDetails}
                                        />
                                    </Item>

                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                            <Button variant="contained" onClick={() => addOrganizationHandler()} color="primary">
                                                Submit
                                            </Button>
                                            <Button variant="contained" onClick={() => handleCloseModalCreateOrg()} color="primary">
                                                Back
                                            </Button>
                                        </Stack>
                                    </Item>
                                </Stack>
                            </Card>
                        </form>
                    </ModalComponent>

                    <ModalComponent
                        width="900"
                        open={openSelectModal}
                        handleClose={handleCloseSelectModal}
                    >
                        <div className="select_plan_type">
                            <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">Select User Type</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    onChange={handlePlanTypeChange}
                                >
                                    <FormControlLabel value="individual" control={<Radio />} label="Individual User" />
                                    <FormControlLabel value="organisation" control={<Radio />} label="Organisation User" />
                                </RadioGroup>
                            </FormControl>
                            <Stack direction="row" spacing={2} marginTop={3} justifyContent="end" alignItems="center">
                                <Button variant="contained" onClick={() => onFilterByUserType()} color="primary">
                                    Submit
                                </Button>
                            </Stack>
                        </div>
                    </ModalComponent>


                    {/* warning modal  */}
                    <ModalComponent
                        width="calc(100% - 30px)"
                        maxwidth="600px"
                        open={warningModal}
                        handleClose={handleCloseWarningModal}
                    >
                        <div className="plan_warning">
                            <h4> <WarningIcon color="info" /> Warning </h4>
                            <p>You have following Active Plans:</p>
                            <div className='plan_warning_table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th> Plan Name </th>
                                            <th> Number of users </th>
                                            <th> Start Date </th>
                                            <th> End Date </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {warningModalData.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td> {item?.plan?.name} </td>
                                                    <td> {item?.num_users} </td>
                                                    <td> {moment(item?.start_date).format('DD.MM.YYYY')} </td>
                                                    <td> {moment(item?.start_end_datedate).format('DD.MM.YYYY')}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <Stack direction="row" spacing={2} marginTop={3} justifyContent="center" alignItems="center">
                                <Button variant="contained" onClick={() => handleWarningnModalConfirm()} color="primary"> Continue </Button>
                                <Button variant="contained" onClick={() => handleCloseWarningModal()} color="inherit"> Cancel </Button>
                            </Stack>
                        </div>
                    </ModalComponent >
                </>
                // </Page>
            );
        } else {
            return (
                <Container>
                    <Card>
                        <Stack padding={5} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                            <h4>Add Plan</h4>
                            <form>
                                <Item>
                                    <TextField
                                        size="small"
                                        style={{ width: '300px' }}
                                        type="text"
                                        label="Plan Name"
                                        variant="outlined"
                                        value={planDetails?.name}
                                        name="name"
                                        onChange={handleChange}
                                    />
                                </Item>
                                <Item>
                                    <TextField
                                        size="small"
                                        style={{ width: '300px' }}
                                        type="text"
                                        label="Number of Weeks"
                                        variant="outlined"
                                        value={planDetails?.num_weeks}
                                        name="num_weeks"
                                        onChange={handleChange}
                                    />
                                </Item>
                                <Item>
                                    <TextField
                                        size="small"
                                        style={{ width: '300px' }}
                                        type="text"
                                        label="Monthly Price"
                                        variant="outlined"
                                        value={planDetails?.monthly_price}
                                        name="monthly_price"
                                        onChange={handleChange}
                                    />
                                </Item>
                                <Item>
                                    <TextField
                                        size="small"
                                        style={{ width: '300px' }}
                                        type="text"
                                        label="Annualy Price"
                                        variant="outlined"
                                        value={planDetails?.annual_price}
                                        name="annual_price"
                                        onChange={handleChange}
                                    />
                                </Item>
                                <Item>
                                    <TextField
                                        size="small"
                                        style={{ width: '300px' }}
                                        type="text"
                                        label="Plan level discount %"
                                        variant="outlined"
                                        value={planDetails?.plan_level_discount}
                                        name="plan_level_discount"
                                        onChange={handleChange}
                                    />
                                </Item>
                                <Item>
                                    <TextField
                                        size="small"
                                        style={{ width: '300px' }}
                                        type="text"
                                        label="Max discount %"
                                        variant="outlined"
                                        value={planDetails?.max_discount}
                                        name="max_discount"
                                        onChange={handleChange}
                                    />
                                </Item>

                                <Item>
                                    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                        <Button variant="contained" onClick={() => addPlansHandler()} color="primary">
                                            Add
                                        </Button>
                                        <Button variant="contained" onClick={() => handleBack()} color="primary">
                                            Back
                                        </Button>
                                    </Stack>
                                </Item>
                            </form>
                        </Stack>
                    </Card>
                </Container>
            );
        }
    }

    return <>{mainBody()}</>;
}
