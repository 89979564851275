// assets
import { GoChecklist } from 'react-icons/go';
import { HiUser } from 'react-icons/hi';
import { MdOutlineAccountBalanceWallet, MdOutlineCampaign } from 'react-icons/md';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

// constant
import {
    IconSettings, IconHome, IconShoppingCart, IconClock, IconBookmark,
    IconHelp, IconClipboardList, IconUsers, IconChecklist, IconBusinessplan, IconWorld, IconBuilding, IconTemplate, IconDiscount2,
    IconInfoCircle,
    IconCreditCard,
    IconReceipt

} from '@tabler/icons';
import { RxPieChart } from "react-icons/rx";
import { RiUser2Line, RiMegaphoneLine, RiFileList2Line, RiFileList3Line } from "react-icons/ri";
import { LiaIndustrySolid } from "react-icons/lia";
import { AiOutlinePartition } from "react-icons/ai";
import { MdOutlineGroups } from "react-icons/md";

// constant
const icons = {
    IconSettings, IconHome, IconShoppingCart, IconClock, IconBookmark,
    IconHelp, IconClipboardList, IconUsers, IconChecklist, IconBusinessplan, IconWorld, IconBuilding, IconTemplate, IconDiscount2,
    IconInfoCircle,
    IconCreditCard,
    IconReceipt
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [

        //root info code
        // {
        //     id: 'plans',
        //     title: 'Org Subscription',
        //     type: 'item',
        //     url: '/dashboard/plans',
        //     icon: MdOutlineCampaign,
        // },
        // {
        //     id: 'organization',
        //     title: 'Organization',
        //     type: 'item',
        //     url: '/main/dashboard/organization',
        //     icon: DomainOutlinedIcon,
        //     role: 'individual'
        // },
        // {
        //     id: 'product-usage-time',
        //     title: 'Product Usage Time ',
        //     type: 'item',
        //     url: '/main/dashboard/product-usage-time',
        //     icon: TimelapseIcon,
        // },
        // {
        //     id: 'product_assignment',
        //     title: 'Product Assignment ',
        //     type: 'item',
        //     url: '/dashboard/user',
        //     icon: AssignmentOutlinedIcon,
        // },
        // {
        //     id: 'timesheet',
        //     title: 'Timesheet ',
        //     type: 'item',
        //     url: '/main/dashboard/timesheet',
        //     icon: PendingActionsIcon,
        // },
        // {
        //     id: 'clients',
        //     title: 'Clients',
        //     type: 'item',
        //     url: '/main/dashboard/clients',
        //     icon: HiUser,
        //     role: 'individual'
        // },
        // {
        //     id: 'controls',
        //     title: 'Controls ',
        //     type: 'item',
        //     url: '/main/dashboard/controls',
        //     icon: ContentPasteIcon,
        // },
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconHome
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/dashboard/users',
            icon: icons.IconUsers
        },
        {
            id: 'organizations',
            title: 'Organizations',
            type: 'item',
            url: '/dashboard/organizations',
            icon: MdOutlineCampaign
        },

        {
            id: 'my-access',
            title: 'My Screen Access',
            type: 'item',
            url: '/dashboard/my-access',
            icon: icons.IconShoppingCart
        },
        {
            id: 'my-data-access',
            title: 'My Data Access',
            type: 'item',
            url: '/dashboard/my-data-access',
            icon: icons.IconShoppingCart
        },

        {
            id: 'products',
            title: 'Products',
            type: 'item',
            url: '/dashboard/products',
            icon: icons.IconShoppingCart
        },
        {
            id: 'plans',
            title: 'Plans',
            type: 'item',
            url: '/dashboard/plans',
            icon: icons.IconBusinessplan,
        },
        {
            id: 'purchase-plans',
            title: 'Purchase Plans',
            type: 'item',
            url: '/dashboard/purchase-plans',
            icon: icons.IconBusinessplan,
        },
        {
            id: 'my-subscriptions',
            title: 'My Subscriptions',
            type: 'item',
            url: '/dashboard/my-subscriptions',
            icon: MdOutlineCampaign,
        },
        {
            id: 'my-new-organization',
            title: 'New Organization',
            type: 'collapse',
            url: '/dashboard/my-new-organization',
            icon: DomainOutlinedIcon,
            children: [
                {
                    id: 'my-new-organization',
                    title: 'Organization Details',
                    type: 'item',
                    url: '/dashboard/my-new-organization',
                    breadcrumbs: true,
                    icon: icons.IconInfoCircle
                },
                // {
                //     id: 'access-role-setup',
                //     title: 'Access Role Setup',
                //     type: 'item',
                //     url: '/dashboard/my-organization',
                //     breadcrumbs: false,
                //     icon: icons.IconCreditCard
                // },
                {
                    id: 'my-organization-members',
                    title: 'Organization Members',
                    type: 'item',
                    url: '/dashboard/my-organization-members',
                    breadcrumbs: true,
                    icon: MdOutlineGroups
                },
                {
                    id: 'my-invoice-template',
                    title: 'Invoice Template',
                    type: 'item',
                    url: '/dashboard/my-invoice-template',
                    breadcrumbs: true,
                    icon: icons.IconReceipt
                }
            ]
        },
        {
            id: 'billing-invoice',
            title: 'Billing',
            type: 'collapse',
            url: '/dashboard/billing-invoice',
            icon: MdOutlineAccountBalanceWallet,
            children: [
                {
                    id: 'billing-invoice',
                    title: 'Invoice',
                    type: 'item',
                    url: '/dashboard/billing-invoice',
                    breadcrumbs: false,
                    icon: RiFileList2Line,
                },
                {
                    id: 'billing-expanse',
                    title: 'Expense',
                    type: 'item',
                    url: '/dashboard/billing-expanse',
                    breadcrumbs: true,
                    icon: RiFileList3Line,
                }
            ]
        },
        // {
        //     id: 'my-organization',
        //     title: 'Organization',
        //     type: 'item',
        //     url: '/dashboard/my-organization',
        //     icon: DomainOutlinedIcon
        // },
        {
            id: 'product-usage-time',
            title: 'Product Usage Time',
            type: 'item',
            url: '/dashboard/product-usage-time',
            icon: TimelapseIcon
        },
        {
            id: 'clients',
            title: 'Clients',
            type: 'item',
            url: '/dashboard/clients',
            icon: RiUser2Line
        },
        {
            id: 'matters',
            title: 'Matters',
            type: 'item',
            url: '/dashboard/matters',
            icon: RiUser2Line,
        },
        {
            id: 'timesheet',
            title: 'Timesheet',
            type: 'item',
            url: '/dashboard/timesheet',
            icon: PendingActionsIcon,
        },
        {
            id: 'new-timesheet',
            title: 'New Timesheet',
            type: 'item',
            url: '/dashboard/new-timesheet',
            icon: icons.IconChecklist,
            breadcrumbs: false
        },
        {
            id: 'country-masterdata',
            title: 'Country MasterData',
            type: 'item',
            url: '/dashboard/country-masterdata',
            icon: icons.IconWorld
        },
        {
            id: 'state-masterdata',
            title: 'State MasterData',
            type: 'item',
            url: '/dashboard/state-masterdata',
            icon: icons.IconBuilding
        },
        {
            id: 'expense-masterdata',
            title: 'Expense Master',
            type: 'item',
            url: '/dashboard/expense-masterdata',
            icon: icons.IconDiscount2
        },
        {
            id: 'discount-masterdata',
            title: 'Discount MasterData',
            type: 'item',
            url: '/dashboard/discount-masterdata',
            icon: icons.IconDiscount2
        }, ,
        {
            id: 'industry-masterdata',
            title: 'Industry MasterData',
            type: 'item',
            url: '/dashboard/industry-masterdata',
            icon: LiaIndustrySolid
        },
        {
            id: 'cliententity-masterdata',
            title: 'Client Entity Masterdata',
            type: 'item',
            url: '/dashboard/cliententity-masterdata',
            icon: RiUser2Line
        },
        {
            id: 'roleinmatter-masterdata',
            title: 'Role In Matter Master',
            type: 'item',
            url: '/dashboard/roleinmatter-masterdata',
            icon: AiOutlinePartition
        },
        {
            id: 'engagementservice-masterdata',
            title: 'Engagement Service Master',
            type: 'item',
            url: '/dashboard/engagementservice-masterdata',
            icon: AiOutlinePartition
        },
        {
            id: 'engagementstatus-masterdata',
            title: 'Engagement Status Master',
            type: 'item',
            url: '/dashboard/engagementstatus-masterdata',
            icon: icons.IconDiscount2
        },
        {
            id: 'datamaster',
            title: 'Data Master',
            type: 'item',
            url: '/dashboard/datamaster',
            icon: icons.IconDiscount2
        },
        {
            id: 'data-role-master',
            title: 'Data Role Master',
            type: 'collapse',
            url: '/dashboard/data-role-master',
            icon: icons.IconDiscount2,
            children: [
                {
                    id: 'data-role-list',
                    title: 'Data List',
                    type: 'item',
                    url: '/dashboard/data-role-list',
                    icon: icons.IconDiscount2
                },
                {
                    id: 'data-role-master',
                    title: 'Data Role Creation',
                    type: 'item',
                    url: '/dashboard/data-role-master',
                    icon: icons.IconDiscount2
                },
            ],
        },
        {
            id: 'screenmaster',
            title: 'Screen Master',
            type: 'item',
            url: '/dashboard/screenmaster',
            icon: icons.IconDiscount2
        },
        {
            id: 'modulemaster',
            title: 'Module Master',
            type: 'item',
            url: '/dashboard/modulemaster',
            icon: icons.IconDiscount2
        },
        {
            id: 'screen-role-master',
            title: 'Screen Role Master',
            type: 'item',
            url: '/dashboard/screen-role-master',
            icon: icons.IconDiscount2
        },
        {
            id: 'designation-master',
            title: 'Designation Master',
            type: 'item',
            url: '/dashboard/designation-master',
            icon: icons.IconDiscount2
        },
        {
            id: 'department-master',
            title: 'Department Master',
            type: 'item',
            url: '/dashboard/department-master',
            icon: icons.IconDiscount2
        },
        {
            id: 'practice-area-master',
            title: 'Practice Area Master',
            type: 'item',
            url: '/dashboard/practice-area-master',
            icon: icons.IconDiscount2
        },

        {
            id: 'template-filter',
            title: 'Template Filter',
            type: 'item',
            url: '/dashboard/template-filter',
            icon: icons.IconTemplate
        },
        {
            id: 'ner-processing',
            title: 'NER Processing',
            type: 'item',
            url: '/dashboard/ner-processing',
            icon: icons.IconChecklist
        },
        {
            id: 'calendar-sync',
            title: 'Calendar Sync',
            type: 'item',
            url: '/dashboard/calendar-sync',
            icon: icons.IconChecklist
        },
        {
            id: 'case-search',
            title: 'Case Search',
            type: 'item',
            url: '/dashboard/case-search',
            icon: icons.IconChecklist
        },
        {
            id: 'template-settings',
            title: 'Template Settings',
            type: 'item',
            url: '/dashboard/template-settings',
            icon: icons.IconChecklist
        },
        {
            id: 'opennyai-setting',
            title: 'OpenNyAI Settings',
            type: 'item',
            url: '/dashboard/opennyai-setting',
            icon: icons.IconChecklist
        },

        // {
        //     id: 'default',
        //     title: 'Timesheet',
        //     type: 'collapse',
        //     url: '/main/dashboard/default',
        //     icon: icons.IconClock,
        //     children: [
        //         {
        //             id: 'tabler-icons',
        //             title: 'Client',
        //             type: 'item',
        //             url: '/main/user',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'material-icons',
        //             title: 'Matter',
        //             type: 'item',
        //             url: '/main/matters',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'userHistory',
        //             title: 'Calendar',
        //             type: 'item',
        //             url: '/main/icons/material-icons',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'userHistory',
        //             title: 'Generate Report',
        //             type: 'item',
        //             url: '/main/icons/material-icons',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'default',
        //     title: 'Support',
        //     type: 'collapse',
        //     url: '/main/dashboard/default',
        //     icon: icons.IconHelp,
        //     children: [
        //         {
        //             id: 'tabler-icons',
        //             title: 'Billing',
        //             type: 'item',
        //             url: '/main/icons/tabler-icons',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'material-icons',
        //             title: 'Tech Supports',
        //             type: 'item',
        //             url: '/main/icons/material-icons',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'userHistory',
        //             title: 'Sales Desk',
        //             type: 'item',
        //             url: '/main/icons/material-icons',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'default',
        //     title: 'Legal',
        //     type: 'item',
        //     url: '/main/dashboard/default',
        //     icon: icons.IconClipboardList
        // }
    ]
};

export default dashboard;
