import React, { useEffect, useState } from 'react'
import { Button, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Stack } from '@mui/system'
import Iconify from 'components/Iconify'
import ModalComponent from 'components/Modal'
import Toast from 'components/Toast'
import { useAccount } from 'context/AccountProvider'
import { getMatterByClient } from 'services/ClientService'
import { DeleteAssignDataRoleMapping, assignValueToData, getDataRoleValue, updateValueToData } from 'services/DataRoleService'
import Loader from 'ui-component/Loader'

function AssignClienMatterPopup({ open, handleClose, activeDataRoleId, allClientList, allMatterList }) {
    const { userDetails } = useAccount();
    const [IsLoading, setIsLoading] = useState(false)
    const [client_matter, setClient_matter] = useState([
        { "client_id": '', "matter_id": '' }
    ])

    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    const [matterList, setMatterList] = useState([])
    const [assignedData, setAssignedData] = useState([])

    useEffect(() => { fetchAssignValueOfDataRole() }, [])

    const fetchAssignValueOfDataRole = async () => {
        const res = await getDataRoleValue(userDetails.organisation.id, activeDataRoleId);
        if (res.data) {
            debugger
            setAssignedData(res?.data[0])
        }
        else { setAssignedData([]) }
    }

    const handleClientMatter = async (e) => {
        const { name, value } = e.target;
        setClient_matter(prevState => [{
            ...prevState[0],  // Preserve the existing matter_id field
            [name]: value  // Update the client_id field with the new value
        }]);

        if (name === 'client_id') {
            setMatterList([]);
            const res = await getMatterByClient(value).then((response) => {
                if (response) {
                    setMatterList(response);
                } else {
                    setToastData({
                        open: true,
                        status: 'error',
                        message: response?.response?.data?.message || 'Something went wrong'
                    });
                }
            })
        }
    };


    //assign value to data role 
    const assignDataValue = async () => {
        setIsLoading(true)
        let payload = {
            "role": activeDataRoleId,
            "data_role": { client_matter: client_matter }
        }

        const res = await assignValueToData(userDetails.organisation.id, payload);

        if (res?.message === "Data created successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchAssignValueOfDataRole()
            setIsLoading(false)
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || 'Something went wrong'
            });
            setIsLoading(false)
        }
    }


    const unAssingnDataValue = async (item) => {
        setIsLoading(true)
        const filteredData = assignedData?.data_role?.client_matter?.filter(_ => !(_.matter_id === item.client_id || _.matter_id === item.matter_id));
        let payload = {
            "role": activeDataRoleId,
            "data_role": { client_matter: filteredData }
        }

        const res = await updateValueToData(userDetails.organisation.id, assignedData?.id, payload);

        if (res?.message === "Data Update successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchAssignValueOfDataRole()
            setIsLoading(false)
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || 'Something went wrong'
            });
            setIsLoading(false)
        }
    }

    return (
        <>
            {IsLoading && <Loader />}
            <ModalComponent width="calc(100% - 40px)" maxWidth="800px" open={open} handleClose={handleClose} >
                <h4>Assign Client & Matter To Data Role</h4>
                <Stack direction="column" justifyContent="space-between" alignItems="center" spacing={1} >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'50%'}>
                                        Client
                                    </TableCell>
                                    <TableCell width={'50%'}>
                                        Matter
                                    </TableCell>
                                    <TableCell width={"200px"}>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {console.log("assignedData", assignedData)}
                            <TableBody>
                                {assignedData?.data_role?.client_matter?.map((item, i) => {
                                    return (
                                        <TableRow key={i}>
                                            {console.log("mk", allClientList?.find((_) => _.id === item?.client_id))}
                                            <TableCell width={'50%'}> {allClientList?.find((_) => _.id === item?.client_id)?.name} </TableCell>
                                            <TableCell width={'50%'}> {allMatterList?.find((_) => _.id === item?.matter_id)?.name} </TableCell>
                                            <TableCell width={'100px'} >
                                                <Iconify style={{ cursor: "pointer" }} icon="eva:trash-2-outline" width={24} height={24} onClick={() => unAssingnDataValue(item)} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}

                                <TableRow>
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <InputLabel size='small' id="demo-simple-select-label">Client</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Client"
                                                name="client_id"
                                                onChange={handleClientMatter}
                                                size='small'
                                            >
                                                <MenuItem value={-1}>None</MenuItem>
                                                {allClientList?.map((item) => (
                                                    <MenuItem value={item?.id}>{item?.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <InputLabel size='small' id="demo-simple-select-label">Matter</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Matter"
                                                name="matter_id"
                                                size='small'
                                                onChange={handleClientMatter}
                                            >
                                                <MenuItem value={-1} disabled>None</MenuItem>
                                                {matterList?.map((item) => (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    {console.log("client_matter11", client_matter[0].client_id.length > 0)}
                                    <TableCell>
                                        <Button variant="contained"
                                            style={{ minWidth: '180px' }}
                                            color="primary"
                                            disabled={client_matter.every(obj => obj.client_id.length < 1 || obj.matter_id.length < 1)}
                                            onClick={assignDataValue}
                                        >
                                            Assign
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Stack>
            </ModalComponent >

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignClienMatterPopup