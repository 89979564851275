import axios from 'axios';

export const getAllOrganizations = async () => {
    try {
        const { data } = await axios.get(`organisations`);
        return data;
    } catch (err) {
        return err;
    }
};
export const createOrganisations = async (payload) => {
    try {
        const { data } = await axios.post(`organisations`, payload);
        return data;
    } catch (err) {
        return err;
    }
};
export const updateOrganisations = async (id, payload) => {
    try {
        const { data } = await axios.put(`organisations/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};
export const addUserInOrganisations = async (id, payload) => {
    try {
        const { data } = await axios.post(`organisations/${id}/add-user`, payload);
        return data;
    } catch (err) {
        return err;
    }
};
export const getUsersOfOrganisations = async (id) => {
    try {
        const { data } = await axios.get(`organisations/${id}/list-users`);
        return data;
    } catch (err) {
        return err;
    }
};
export const listUserProductAssignment = async (orgId) => {
    try {
        const { data } = await axios.get(`organisations/${orgId}/list-user-product-assignment`);
        return data;
    } catch (err) {
        return err;
    }
};


//users/organization-all-user
export const getAllOrganizationUsers = async () => {
    try {
        const { data } = await axios.get(`users/organization-all-user`);
        return data;
    } catch (err) {
        return err;
    }
}